import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {ScrollPanelModule} from "primeng/scrollpanel";
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {windowHeight} from "../../traits/window-tools";
@Component({
  selector: 'app-view-grid',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    ScrollPanelModule,
  ],
  templateUrl: './view-grid.component.html',
})
export class ViewGridComponent {
  @Input() showSelect: boolean = false;
  @Input() public selectCard:any;
  @Input() public cards:any[];
  @Output() onSelect = new EventEmitter();
  ngOnInit() {
    this. selectCard = this.cards[0];
  }

  public setSelect(itemCard:any){
    this.selectCard = itemCard;
    this.onSelect.emit(itemCard);
  }


  protected readonly windowHeight = windowHeight;
}
