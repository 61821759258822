import {
  inject,
  Injectable
} from '@angular/core';
import {StaticArtasServices} from '../static/static-artas.services';
import {ServerSiteServices} from '../servers/server-site.services';
import {SeoService} from '../common/seo.services';
import {dateNow} from "../../traits/datetime-tools";

interface Seo {
  'title': string,
  'description': string,
  'text': string,
  'textEndPage': string,
  'h1': string,
  'h2': string,
  'h3': string,
  'h4': string,
  'h5': string,
  'keywords': Array<string>,
}

@Injectable({
  providedIn: 'root',
})

export class PageArtasServices {
  public SERVER_NAME: string = 'artas';
  public DOMAIN: string = 'art-club.artas.org.ua/artas';
  public route: string = 'artas';

  public fromServer: boolean = false;
  public premium: object = {
    name: 'artas',
    desc: '',
    additions: [''],
  };
  public free: object = {
    name: 'artas',
    desc: '',
    additions: [''],
  };
  public seoTexts: Seo = {
    title: ' 🌈🎨  ШАРЖ ПО ФОТО, ЗАКАЗАТЬ ШАРЖ / КАРИКАТУРУ - КИЕВ УКРАИНА',
    description: 'ШАРЖ ПО ФОТО, ЗАКАЗАТЬ ШАРЖ / КАРИКАТУРУ КИЕВ. Шарж недорого и быстро - от 300 грн. Заказать шарж по фото по фото.',
    text: 'ШАРЖ ПО ФОТО- это картина, в которой герой помещен в сцену, наполненную смысловыми нюансами и деталями. Позволяет отобразить его увлечения, хобби, увлечения, шарж может быть наполнен и определенной тематикой-сюжетом, шарж можна подарить на, день рождения, юбилей, и другие собития  ',
    h1: 'ШАРЖ ПО ФОТО, ЗАКАЗАТЬ ШАРЖ / КАРИКАТУРУ',
    h2: 'ШАРЖ ПО ФОТО ОНЛАЙН, ЦИФРОВОЙ, КАРАНДАШОМ',
    h3: 'ШАРЖ ПО ФОТО СЮЖЕТНИЙ, ПЕРСОНАЖНЫЙ, ПОРТРЕТНЫЙ',
    h4: '',
    h5: '',
    textEndPage: '',
    keywords: [
      'Шарж по фото',
      'Шарж на заказ',
      'Заказать шарж',
      'Заказать карикатуру',
      'Карикатура по фото',
    ],
  };

  public site: any

  constructor(public server: ServerSiteServices) {
    this.getSite();
    this.initSeo();
  }

  getSite() {
      if (this.fromServer) {
        return this.getSiteFromServer();
      } else {
        return this.getSiteStatic();
      }

  }

  public objectToString(objectJson: any) {
    return JSON.stringify(objectJson);
  }

  public saveTextFile(text: any, filename: any) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  public downloadSiteBtn(site: any) {
    let siteText: string = this.objectToString(site);
    this.saveTextFile(siteText, this.site.domain + '_' + dateNow() + '_.txt')
  }

  public saveSite(site: object) {
    this.site = site
    this.downloadSiteBtn(this.site);
    // let server: any = inject(ServerSiteServices)
    this.server.saveSite(this.DOMAIN, this.site);
  }

  getSiteStatic() {
    return this.site = inject(StaticArtasServices).site;

  }

  initSeo(): void {
    let seoServices: any = inject(SeoService);
    seoServices.setMetaTags(this.seoTexts.title, this.seoTexts.description, this.seoTexts.keywords,)

  }

  public getSiteFromServer() {
    let server: any = inject(ServerSiteServices)
    server.initSite(this.SERVER_NAME, (responseSite:any): void => {
      this.site = responseSite;
    });
    return this.site;
  }
  getCards(){
    return  this.site.organizer.messenges;
  }
  findCard(slug:any){
    console.log(slug)
    let cards = this.getCards();
    let findCard =   cards.filter((itemProduct:any)=>{
      if(itemProduct.id === slug){
        return itemProduct;
      }
    });
    return findCard[0];
  }
}
