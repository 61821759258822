<div
  class="relative bg-color-custom mt-[50px]  "
>
  <nav
    class=" animate-fade-left animate-once animate-ease-in-out z-10 flex shrink-0 grow-0 justify-around gap-1 border-t border-gray-200 bg-color-custom/50 p-1 backdrop-blur-lg  fixed top-[50px] -translate-y-2 -left-[20px] min-h-[auto] min-w-[64px] flex-col rounded-lg shadow-lg  border"
  >
    <p-scrollPanel [style]="{width:100 +'px',height:windowHeight()+'px'}">
      <ng-container *ngFor="let  itemCard  of cards; let first = first">

        <a
          (click)="onSelectCard.emit(itemCard)"
          [ngClass]="itemCard === selectCard ?'bg-amber-400':'bg-color-custom'"
          class="flex aspect-square min-h-[32px] w-[100px] flex-col items-center justify-center gap-1 rounded-md p-1  text-amber-600"
        >
          <img
            [src]="itemCard.srcPreview"
            [alt]="itemCard.name"
            class="h-[100px] w-[100px] shadow-lg  border border-zinc-700   "
          >
          <!-- <small class=" truncate text-center text-xs font-bold w-[40px]">
               <span>
                 {{ itemCard.name }}
               </span>
           </small>-->
        </a>
      </ng-container>
    </p-scrollPanel>
  </nav>
</div>
