<!--<app-preload></app-preload>-->

@if (selectCard && showPhotos) {
  <app-sidebar [show]="showPhotos"
               (onSave)="closePhotos($event)"
               [showClose]="true"
               [height]="100"
               [ext]="'%'"
               [zIndex]="10000"
               [position]="'bottom'"
  >
    <app-box-viewer-photos
      [boxId]="boxFileName(selectCard)"
      (onDestroy)="closePhotos($event)"
      (onGeneratePallete)="setGeneratePallete($event)"
    >
    </app-box-viewer-photos>
  </app-sidebar>
}
<!--<app-scroll-next-preview
  [cards]="dataPage.site.organizer.messenges"
></app-scroll-next-preview>-->

<app-scroll-cards-horizontal
  [cards]="dataPage.site.organizer.messenges"
></app-scroll-cards-horizontal>

<app-list-feed
  [cards]="dataPage.site.organizer.messenges"
  (onSelect)="selectItemCard($event)"
  (onSelectCourse)="onSelectCourse.emit($event)"
  (onShowPhotos)="this. selectCard = $event; showPhotos = true "
  (onClickRoute)="onClickRoute.emit($event)"
  (onFrameShow)="this. selectCard = $event; showFrame = true "
></app-list-feed>

<!--
<app-header
  [gp] = "gp"
  [site] = "site"
  [bgImage] = "'assets/header-background.jpg'"
  [headerText]  = "'Art-club'"
  [subHeaderText] = "'Art-club'"
  [messengesName] ="'Послуги'"
  [actionText] ="'Art-club'"
  [animationClass]="'Art-club'"

></app-header>
<ng-container>
  <app-list-courses
    *ngIf="(cd.showEditAll === false && gp.messengesClone)"
    [site]="this.site"
    [messengers]="gp.messengesClone"
    [gp]="gp"
  ></app-list-courses>

  <app-list-courses
    *ngIf="(cd.showEditAll === true && site?.organizer?.messenges)"
    [site]="this.site"
    [messengers]="site?.organizer?.messenges"
    [gp]="gp"
  ></app-list-courses>
</ng-container>
<app-card-edit [site]="site"
               [gp]="gp"
></app-card-edit>


<app-tool-sidebar [show]="this.cd.showKonva"
                  (onSave)="this.cd.showKonva =false"
                  [showClose]="false"
                  [height]="100"
                  [ext]="'%'"
                  [position]="'bottom'"
>
  <app-tool-konva-card-edit *ngIf="(this.cd.showKonva && cd.selectModel)"
                            [site]="site"
                            [preloadSite]="preloadSite"
                            [model]="cd.selectModel"
                            (onRender)="cd.saveSite(site);cd.savePreload(preloadSite)"
  ></app-tool-konva-card-edit>
</app-tool-sidebar>

<app-tool-sidebar [position]="'top'" [height]="45"
                  [show]="cd.showExpandModel" [showClose]="false"
                  [modal]="true"
                  (onSave)="cd.showExpandModel =false; cd.saveSite(site,'save')"
>
  <ng-container *ngIf="( cd.selectModel && cd.showExpandModel )">
    <app-tool-name
      [clear]="cd.showDeleteBtn"
      [showDelete]="cd.showDeleteBtn"
      [copy]="cd.showCopyBtn"
      [showColor]="cd.showColorBtn"
      [showCheck]="cd.showCheckBtn"
      [showImage]="cd.showImageBtn"
      [showSw]="cd.showShowBtn"
      [showMore]="cd.showMoreBtn"
      [showText]="cd.showTextBtn"
      [showLock]="cd.showLockBlock"
      [showTranslate]="cd.showTranslateBtn"
      [lineHeight]="30"
      (onDelete)="cd.debug('onDelete')"
      (onCheck)="cd.debug('onCheck')"
      (onShow)="cd.debug('onShow')"
      [model]="cd.selectModel"
      [models]="cd.selectModels"
    ></app-tool-name>
    &lt;!&ndash;  <button pButton
              class="btn btn-default m-1 w-100"
              [icon]="cd.theme.icon.save"
              (click)="cd.saveSite(site,'save')"
              [label]="'Save Site'"

      ></button>&ndash;&gt;
  </ng-container>
</app-tool-sidebar>
<app-tool-sidebar [position]="'top'" [height]="45"
                  [show]="cd.showEditModel" [showClose]="false"
                  [modal]="true"
                  (onSave)="cd.showEditModel =false; cd.saveSite(site,'save')"
>
  <ng-container *ngIf="( cd.selectCardModel && cd.showEditModel )">
    <app-tool-name
      [clear]="cd.showDeleteBtn"
      [showDelete]="cd.showDeleteBtn"
      [copy]="cd.showCopyBtn"
      [showColor]="cd.showColorBtn"
      [showCheck]="cd.showCheckBtn"
      [showImage]="cd.showImageBtn"
      [showSw]="cd.showShowBtn"
      [showMore]="cd.showMoreBtn"
      [showText]="cd.showTextBtn"
      [showLock]="cd.showLockBlock"
      [showTranslate]="cd.showTranslateBtn"
      [lineHeight]="30"
      (onDelete)="cd.debug('onDelete')"
      (onCheck)="cd.debug('onCheck')"
      (onShow)="cd.debug('onShow')"
      [model]="cd.selectCardModel"
    ></app-tool-name>
    &lt;!&ndash;  <button pButton
              class="btn btn-default m-1 w-100"
              [icon]="cd.theme.icon.save"
              (click)="cd.saveSite(site,'save')"
              [label]="'Save Site'"

      ></button>&ndash;&gt;
  </ng-container>
</app-tool-sidebar>
&lt;!&ndash;TODO add menu&ndash;&gt;
<app-tool-menu-top-bar></app-tool-menu-top-bar>
<app-tool-menu-bottom-bar></app-tool-menu-bottom-bar>

<app-tool-cards-view
  [saveModel]="preloadSite"
  [height]="300"
  [width]="300"
  [arrayName]="'cards'"
></app-tool-cards-view>

<div *ngIf="!this.site" class="inline-block px-3 text-center">
  <app-tool-spinner></app-tool-spinner>
</div>

<div class="sticky top-10 mt-5 drop-shadow shadow-gray-600 z-20">
  <app-tool-cards-view *ngIf="(!cd.preloadSite && showQuickCards && messenge)"
                       [saveModel]="this.messenge"
                       [height]="100"
                       [width]="100"
                       [view]="'cost'"
                       [arrayName]="'array'"
  ></app-tool-cards-view>
</div>

<div *ngIf=" this.messenge" class=" container center-container">
  &lt;!&ndash;  <ng-container *ngFor="let  messenge  of this.site.organizer.messenges.slice(0,cd.showCountCards) ">&ndash;&gt;
  <ng-container *ngIf="this.messenge.sw">
    <div class=" grid grid-cols-1    md:grid-cols-1   lg:grid-cols-3   ">
      <ng-container *ngFor="let  arrayItem  of  messenge.array.slice(0,cd.showCardsLength)">
        <app-tool-card-site [model]="arrayItem" [models]="messenge.array"></app-tool-card-site>
      </ng-container>
    </div>
  </ng-container>
  &lt;!&ndash;  </ng-container>&ndash;&gt;
</div>

-->

<!--

<app-header
  [gp]="gp"
>
</app-header>




<app-course-video-viewer
  *ngIf="(gp.showCourseViewer && gp.selectCourse)"
  [gp]="gp"
  [selectCourse]="gp.selectCourse"
>
</app-course-video-viewer>



<ng-container >

  <app-list-courses
    *ngIf="(cd.showEditAll === false && gp.messengesClone)"
    [site]="this.site"
    [messengers]="gp.messengesClone"
    [gp]="gp"
  ></app-list-courses>

  <app-list-courses
    *ngIf="(cd.showEditAll === true && site.organizer.messenges)"
    [site]="this.site"
    [messengers]="site.organizer.messenges"
    [gp]="gp"
  ></app-list-courses>

  &lt;!&ndash; <app-tool-sidebar [show]="this.cd.showKonva"
                     (onSave)="this.cd.showKonva =false"
                     [showClose]="false"
                     [height]="100"
                     [ext]="'%'"
                     [position]="'bottom'"
   >
     &lt;!&ndash; <app-tool-konva-card-edit *ngIf="(this.cd.showKonva && cd.selectModel)"
                                [site]="site"
                                [model]="cd.selectModel"
                                (onRender)="cd.saveSiteLib(site,'save')"
      ></app-tool-konva-card-edit>&ndash;&gt;
   </app-tool-sidebar>&ndash;&gt;

  &lt;!&ndash; todo <app-tool-sidebar [show]="this.cd.showSiteEditor"
                     (onSave)="this.cd.showSiteEditor =false"
                     [showClose]="false"
                     [height]="100"
                     [ext]="'%'"
                     [position]="'bottom'"
   >
     &lt;!&ndash;<app-site-editor
       [site]="site"
       (onSave)="cd.saveSiteLib(site,'save');this.cd.showSiteEditor =false"
     ></app-site-editor>&ndash;&gt;
   </app-tool-sidebar>&ndash;&gt;

  <app-card-edit [site]="site"></app-card-edit>

</ng-container>
-->

<!--<app-sidebar [show]="imageForGeneratePalette"
             (onSave)="imageForGeneratePalette = null"
             [showClose]="true"
             [height]="100"
             [ext]="'%'"
             [zIndex]="10000"
             [position]="'bottom'"
>
  @if(imageForGeneratePalette){
    <app-pallete-generator-image
      [imageParent]="imageForGeneratePalette"
    ></app-pallete-generator-image>
  }
</app-sidebar>-->
