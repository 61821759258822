<div   style="height:25px; overflow: hidden"
       class="absolute top-0 seo-container text-center z-0">
  <h1>{{dataPage.seoTexts.h1}}</h1>
  <h2>{{dataPage.seoTexts.h2}}</h2>
  <h3>{{dataPage.seoTexts.h3}}</h3>
  <p>{{dataPage.seoTexts.text}}</p>
</div>
<app-contacts-left
  [showForm]="false"
>
</app-contacts-left>
<app-navbar-top-artas
  [routeHome]="'/zakazat-sharj-po-foto'"
></app-navbar-top-artas>
<app-product-list-right
  [cards]="cards"
  [selectCard]="card"
  (onSelectCard)="setSelect($event)"
></app-product-list-right>
<app-contacts-left
  [showForm]="true"
  [selectCard]="card">
</app-contacts-left>
<app-product-more
  [selectCard]="card"

></app-product-more>
<app-view-grid *ngIf="photos"
               [cards]="photos"
></app-view-grid>

<!--


<div   style="height:25px; overflow: hidden"
       class="absolute top-0 seo-container text-center z-0">
  <h1>{{dataPage.seoTexts.h1}}</h1>
  <h2>{{dataPage.seoTexts.h2}}</h2>
  <h3>{{dataPage.seoTexts.h3}}</h3>
  <p>{{dataPage.seoTexts.text}}</p>
</div>
<app-navbar-top-artas></app-navbar-top-artas>
<app-header
  [headerText]="dataPage.seoTexts.h1"
  [subHeaderText]="dataPage.seoTexts.h2"
></app-header>
<div class="bg-color-custom">
  <app-common-home
    [dataPage]="dataPage"
  ></app-common-home>
</div>
<app-navbar-bottom-artas></app-navbar-bottom-artas>



&lt;!&ndash;
<app-tool-form></app-tool-form>
<app-menu-top-sharg
  [items]="this.cd.menuTopHamburgerItems"
></app-menu-top-sharg>

<app-header-courses
  [gp] = "gp"
  [site] = "site"
  [bgImage] = "'assets/header-background.jpg'"
  [headerText]  = "'Шаржі по фото'"
  [subHeaderText] = "'Шаржі на будь який смак, сюжетні, персонажні та портретні'"
  [messengesName] ="'Послуги'"
  [actionText] ="'Шаржі пастеллю, шаржі графітним олівцем та цифровим пензлем '"
  [animationClass]="'animate-fade-down animate-once '"

></app-header-courses>

<ng-container>
  <app-list-courses
    *ngIf="(cd.showEditAll === false && gp.messengesClone)"
    [site]="this.site"
    [messengers]="gp.messengesClone"
    [gp]="gp"
  ></app-list-courses>

  <app-list-courses
    *ngIf="(cd.showEditAll === true && site.organizer.messenges)"
    [site]="this.site"
    [messengers]="site.organizer.messenges"
    [gp]="gp"
  ></app-list-courses>
</ng-container>
<app-card-edit [site]="site"
               [gp]="gp"
></app-card-edit>
<app-menu-bottom-sharg
  [items]="this.cd.menuTopHamburgerItems"
></app-menu-bottom-sharg>










&lt;!&ndash;&lt;!&ndash; HEAD FORM ORDER&ndash;&gt;
<app-tool-form></app-tool-form>
&lt;!&ndash; MENU TOP&ndash;&gt;

<app-menu-top-sharg
  [items]="this.cd.menuTopHamburgerItems"
></app-menu-top-sharg>


&lt;!&ndash; HEAD SCROLL LINK SLIDER&ndash;&gt;
<app-tool-cards-view
  [site]="site"
  [saveModel]="preloadSite"
  [height]="300"
  [width]="300"
  [arrayName]="'cards'"
></app-tool-cards-view>

&lt;!&ndash; HEAD HEADER CARDS LIST&ndash;&gt;
<div class="sticky top-10 mt-5 drop-shadow shadow-gray-600 z-20">
  <app-tool-cards-view *ngIf="(!cd.preloadSite && showQuickCards && messenge)"
                       [saveModel]="this.messenge"
                       [height]="100"
                       [width]="100"
                       [view]="'cost'"
                       [arrayName]="'array'"
                       [site]="site"
  ></app-tool-cards-view>
</div>

&lt;!&ndash; HEAD ACTIONS&ndash;&gt;
<app-action-block-sharg
  [site]="site"
></app-action-block-sharg>
&lt;!&ndash; HEAD CONTENT CARDS LIST&ndash;&gt;

<div *ngIf=" this.messenge" class=" container center-container">
  &lt;!&ndash;  <ng-container *ngFor="let  messenge  of this.site.organizer.messenges.slice(0,cd.showCountCards) ">&ndash;&gt;
  <ng-container *ngIf="this.messenge.sw">
    <div class=" grid grid-cols-1    md:grid-cols-1   lg:grid-cols-2   ">
      <ng-container *ngFor="let  arrayItem  of  messenge.array.slice(0,cd.showCardsLength)">
        <app-tool-card-site
          [site]="site"
          [model]="arrayItem"
          [models]="messenge.array"
        ></app-tool-card-site>
      </ng-container>
    </div>
  </ng-container>
  &lt;!&ndash;  </ng-container>&ndash;&gt;
</div>

<app-menu-bottom-sharg
  [items]="this.cd.menuTopHamburgerItems"
></app-menu-bottom-sharg>&ndash;&gt;


&lt;!&ndash;<div class=" my-5 drop-shadow shadow-gray-600 row-scroll"
     style="background-color:#812828">
  <div *ngIf="site?.headerText" class="flex justify-center">
    <span class="inline">
      <img width="50"
           height="50"
           src="assets/icons/libs-icons/2349820.png"
           [alt]="site.headerText"
      >
    </span>
    <span class="desc-block text-center ">
     {{ site.headerText}}
        </span>
    <span class="inline " style="transform: scaleX(-1);">
      <img width="50"
           height="50"
           src="assets/icons/libs-icons/2349820.png"
           [alt]="site.headerText"
      >
    </span>
  </div>&ndash;&gt;

&lt;!&ndash;</div>&ndash;&gt;



&lt;!&ndash; HEAD SPINNER&ndash;&gt;
&lt;!&ndash;<div class="w-100  px-3 text-center">
  <app-tool-spinner *ngIf="!this.site"
                    (onInit)="cd.debug($event)"
                    (onDestroy)="hideSpinner()"
  ></app-tool-spinner>
</div>&ndash;&gt;


&lt;!&ndash;  <div *ngIf="cd.showEditAll" style=" height:20px!important; padding:0;margin-top: 0;" class="p-inputgroup">
    <button class="btn-custom" (click)="site.showDiscount =!site.showDiscount">showDiscount</button>
    <span class="p-inputgroup-addon">
                 <input
                   type="text"
                   pInputText
                   placeholder="video.srcPreview"
                   [(ngModel)]="site.discountText"
                 >
        </span>
  </div>&ndash;&gt;
&lt;!&ndash; HEAD  EDITOR START&ndash;&gt;
&lt;!&ndash;<app-tool-sidebar [show]="this.cd.showKonva"
                  (onSave)="this.cd.showKonva =false"
                  [showClose]="false"
                  [height]="100"
                  [ext]="'%'"
                  [position]="'bottom'"
>
  <app-tool-konva-card-edit *ngIf="(this.cd.showKonva && cd.selectModel)"
                            [site]="site"
                            [preloadSite]="preloadSite"
                            [model]="cd.selectModel"
                            (onRender)="cd.saveSite(site);cd.savePreload(preloadSite)"
  ></app-tool-konva-card-edit>
</app-tool-sidebar>&ndash;&gt;
&lt;!&ndash;<app-tool-sidebar [position]="'top'" [height]="80"
                  [show]="cd.showExpandModel" [showClose]="false"
                  [modal]="true"
                  (onSave)="cd.showExpandModel =false; cd.saveSite(site,'save')"
>
  <ng-container *ngIf="( cd.selectModel && cd.showExpandModel )">
    <app-tool-name
      [site]="site"
      [clear]="cd.showDeleteBtn"
      [showDelete]="cd.showDeleteBtn"
      [copy]="cd.showCopyBtn"
      [showColor]="cd.showColorBtn"
      [showCheck]="cd.showCheckBtn"
      [showImage]="cd.showImageBtn"
      [showSw]="cd.showShowBtn"
      [showMore]="cd.showMoreBtn"
      [showText]="cd.showTextBtn"
      [showLock]="cd.showLockBlock"
      [showTranslate]="cd.showTranslateBtn"
      [lineHeight]="30"
      (onDelete)="cd.debug('onDelete')"
      (onCheck)="cd.debug('onCheck')"
      (onShow)="cd.debug('onShow')"
      [model]="cd.selectModel"
      [models]="cd.selectModels"
    ></app-tool-name>
    &lt;!&ndash;  <button pButton
              class="btn btn-default m-1 w-100"
              [icon]="cd.theme.icon.save"
              (click)="cd.saveSite(site,'save')"
              [label]="'Save Site'"

      ></button>&ndash;&gt;
  </ng-container>
</app-tool-sidebar>&ndash;&gt;

&lt;!&ndash;<div *ngIf="cd.showEditAll" style=" height:20px!important; padding:0;margin-top: 0;" class="p-inputgroup">
    <span class="p-inputgroup-addon">
               <input
                 type="text"
                 pInputText
                 placeholder="headerText"
                 [(ngModel)]="site.headerText"
               >
      </span>
</div>&ndash;&gt;
&lt;!&ndash;HEAD EDITOR END&ndash;&gt;

&lt;!&ndash; HEAD VIDEOS SLIDER&ndash;&gt;
&lt;!&ndash;<app-tool-video-slider
  *ngIf="
                   site &&
                   site.videos &&
                   gp.showVideos"
  [videos]="site.videos"
  [showVideoList]="false"
  [showVideoWatch]="false"
></app-tool-video-slider>&ndash;&gt;

&lt;!&ndash;<app-tool-sidebar [position]="'top'" [height]="45"
                  [show]="cd.showEditModel" [showClose]="false"
                  [modal]="true"
                  (onSave)="cd.showEditModel =false; cd.saveSite(site,'save')"
>
  <ng-container *ngIf="( cd.selectCardModel && cd.showEditModel )">
    <app-tool-name
      [site]="site"

      [clear]="cd.showDeleteBtn"
      [showDelete]="cd.showDeleteBtn"
      [copy]="cd.showCopyBtn"
      [showColor]="cd.showColorBtn"
      [showCheck]="cd.showCheckBtn"
      [showImage]="cd.showImageBtn"
      [showSw]="cd.showShowBtn"
      [showMore]="cd.showMoreBtn"
      [showText]="cd.showTextBtn"
      [showLock]="cd.showLockBlock"
      [showTranslate]="cd.showTranslateBtn"
      [lineHeight]="30"
      (onDelete)="cd.debug('onDelete')"
      (onCheck)="cd.debug('onCheck')"
      (onShow)="cd.debug('onShow')"
      [model]="cd.selectCardModel"
    ></app-tool-name>
    &lt;!&ndash;  <button pButton
              class="btn btn-default m-1 w-100"
              [icon]="cd.theme.icon.save"
              (click)="cd.saveSite(site,'save')"
              [label]="'Save Site'"

      ></button>&ndash;&gt;
  </ng-container>
</app-tool-sidebar>&ndash;&gt;
&ndash;&gt;
-->
