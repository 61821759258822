



<div class="ml-6 mt-2">
  <div
    [class]="'bgColor'"
    [style.background-image]="'url(' + 'assets/background_logo_small.png' + ')'"
    class=" bg-[length:50px_50px] bg-opacity-10  bg-repeat flex justify-center items-center flex-col bg-gradient-to-br from-gray-800 to-gray-900 relative animate-gradient-x">

    <div class="m-3 p-3">
      <div class="w-full  p-3 rounded-lg shadow-lg">
        <p-scrollPanel [style]="{height:windowHeight()-100 +'px'}">
          <div class="flex justify-center">
            <div class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-1" >
              <ng-container *ngFor="let  itemCard  of cards; let first = first">
                <div class=" rounded-lg shadow-lg  border border-zinc-700 text-center aspect-square relative  inline-block transition-transform transform hover:scale-105">
                  <img [src]="itemCard.srcPreview" alt="upload_image"
                       class=" "
                  >
                  <!-- <button  (click)="check(uploadItem)"
                            class="text-sm absolute top-0 left-2 bg-green-500 text-white p-1 rounded-full transition-transform transform hover:scale-125"
                   >
                     <i *ngIf="uploadItem.check" [class]="'fas fa-check'"></i>
                     <i *ngIf="!uploadItem.check" [class]="'far fa-square'"></i>
                   </button>-->
                  <!--<button #targetEl
                    (click)="selectCard =itemCard;"
                    class="w-[25px] h-[25px] text-md absolute top-0 -right-2 bg-color-custom text-white rounded-full transition-transform transform hover:scale-125"
                    >
                    <i class="fa-solid fa-info"></i>
                  </button>-->

                  <!-- <div class="flex flex-row gap-1 w-[150px] ">

                     <button  *ngIf="showSelect"
                         class="w-full bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white font-bold py-2 px-4 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105 animate-pulse fade-out"
                        (click)="setSelect(itemCard);"
                     >
                     Open
                     </button>

                   </div>-->
                </div>
              </ng-container>
            </div>
          </div>
        </p-scrollPanel>
      </div>
    </div>
    <div class="mt-1 mb-1">
    </div>
  </div>
</div>
<!--<p-overlayPanel #opInfoBot>
  @if (selectCard){
    <div class="max-w-sm rounded overflow-hidden shadow-lg transform hover:scale-110 transition duration-500 cursor-pointer">
      <div class="bg-gradient-to-br from-pink-500 via-blue-500 to-yellow-500 p-5 rounded-t">
        <h2 class="font-bold text-white text-xl mb-2">{{selectCard.name}}</h2>
        <p class="text-white text-opacity-90 text-sm">
          {{selectCard.desc}}
        </p>
      </div>
    </div>
  }

</p-overlayPanel>-->
