import {Component, Input} from '@angular/core';
import {
  Router,
  RouterLink
} from '@angular/router';
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {MyDataServices} from "../../services/my-data.services";

@Component({
  selector: 'app-navbar-top-artas',
  templateUrl: './navbar-top-artas.component.html',
  standalone:true,
  imports: [
    NgIf,
    NgForOf,
    RouterLink
  ]
})
export class NavbarTopArtasComponent {
  @Input() logoText: any ='ART-PARTY.KIEV.UA';
  @Input() routeHome: any = '/';
  constructor(
              public myData:MyDataServices,
              private router: Router,

  ) {
  }

  goHome() {
    // this.router.navigate([this.routeHome]);
  }
}
