<header
  class="z-20 bg-color-custom fixed top-0 w-full h-[50px]  transition-all  shadow-md pt-0">
  <nav class=" h-[4px] max-w-screen-xl  mx-auto grid grid-flow-col">
    <div class="  text-orange-500 col-start-1 col-end-2 flex items-center">

      <a [routerLink]="routeHome" (click)="goHome()"
        class=" animate-fade-down animate-once animate-ease-in-out ml-2 mr-1 mt-3 flex items-center text-[15px]  text-amber-500 font-bold  lg:mb-0 lg:mt-0"
         >
        <img class="mr-1"
             [src]="'assets/logo.jpg'"
             style="height: 25px"
             [alt]="logoText"
        />
        {{logoText}}

      </a>
    </div>
    <div class="col-start-10 col-end-12 font-medium flex justify-end items-center mr-3">
      <div class="" id="navbar-sticky ">
        <ul class=" flex items-center justify-between w-full text-sm">
          <a href="/zakazat-sharj" class="flex items-center">
            <img [src]="myData.personal.flagSrc" class=" animate-bounce animate-infinite animate-duration-[2000ms] w-[15px] h-[10px ] mr-3" alt="">
            <span
              class="   text-pink-100 self-center text-[12px] font-semibold whitespace-nowrap">
        {{myData.personal.cityCountry}}
      </span>
          </a>
        </ul>
      </div>

    </div>
  </nav>
</header>
