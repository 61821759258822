import {NgModule} from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import {PageArtPartyComponent} from "./pages/pages-jome/page-art-party/page-art-party.component";
import {PageArtasProductsComponent} from "./pages/pages-jome/page-artas-products/page-artas-products.component";
import {PageArtasComponent} from "./pages/pages-jome/page-artas/page-artas.component";
import {PageArtPartyProductsComponent} from "./pages/pages-jome/page-art-party-products/page-art-party-products.component";

const routes: Routes = [
  {
    path: "",
    component: PageArtPartyComponent,
    pathMatch: 'full',

  },
 /* {
    path: "sharjist",
    component: PageArtPartyComponent,
    pathMatch: 'full',
  },*/
  {
    path: "sharjist/:slug",
    component: PageArtPartyProductsComponent,
    pathMatch: 'full',
  },
  {
    path: "zakazat-sharj-po-foto",
    component: PageArtasComponent,
    pathMatch: 'full',
  },
  {
    path: "zakazat-sharj-po-foto/:slug",
    component: PageArtasProductsComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
