import {Component} from '@angular/core';
import {
  NgForOf,
  NgIf
} from "@angular/common";
import { HeaderComponent } from 'src/app/components/pages/header/header.component';
import {NavbarTopComponent} from "../../../components/pages/navbar-top/navbar-top.component";
import {CommonHomeComponent} from "../../common-home/common-home.component";
import {ContactsLeftComponent} from "../../../components/contacts-left/contacts-left.component";
import {
  ActivatedRoute,
  Router
} from "@angular/router";
import {NavbarTopArtasComponent} from "../../../components/navbar-top-artas/navbar-top-artas.component";
import {PageArtPartyServices} from "../../../services/pages/page-art-party.services";
@Component({
  selector: 'app-page-art-party.component.html',
  templateUrl: './page-art-party.component.html',
  standalone:true,
  imports: [
    HeaderComponent,
    NavbarTopComponent,
    CommonHomeComponent,
    NgForOf,
    NgIf,
    NavbarTopArtasComponent,
    ContactsLeftComponent,
    CommonHomeComponent,
  ],
})
export class PageArtPartyComponent {
  constructor(public dataPage: PageArtPartyServices,
              private router: Router,
              private route: ActivatedRoute,) {}

  setSelect(card: any) {
    this.router.navigate(['sharjist/',card.id]);

  }
}
