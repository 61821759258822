import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {NgFor, NgIf} from '@angular/common';
import {Card} from '../../../interfaces/Card';
import {ThemeServices} from '../../../services/common/theme.services';
import {GifGeneratorComponent} from '../../image/gif-generator/gif-generator.component';
import {SvgIconComponent} from '../../image/svg-icon/svg-icon.component';
import {BtnGoComponent} from '../../bottons/btn-go/btn-go.component';
import {theme} from '../../../interfaces/theme';
import {CdServices} from "../../../services/common/cd.services";
import {BtnGoServices} from "../../../services/common/btn-go.services";

@Component({
  selector: 'app-card-feed',
  templateUrl: './card-feed.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    GifGeneratorComponent,
    SvgIconComponent,
    BtnGoComponent,
  ]
})
export class CardFeedComponent implements OnInit {
  @Input() showImage: boolean = true;
  @Input() card: Card;
  @Input() bgGlobalClass: string = 'bg-[#4a0620]';
  @Input() bgClass: string = 'bg-[#4a0620]';
  @Input() borderClass: string = 'border-[#4a0620]';
  @Output() onDeleteFromFavorite: EventEmitter<any> = new EventEmitter();
  public theme: theme = inject(ThemeServices);

  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onSelectCourse: EventEmitter<any> = new EventEmitter();
  @Output() onAddFavorite: EventEmitter<any> = new EventEmitter();
  @Output() onFrameShow: EventEmitter<any> = new EventEmitter();
  @Output() onShowPhotos: EventEmitter<any> = new EventEmitter();
  @Output() onClickRoute: EventEmitter<any> = new EventEmitter();

  ngOnInit():void {
  }
  constructor(public cd: CdServices,) {
  }

  showPhotosBoxBtn(item: Card, uploaded: string): void {
    this.onShowPhotos.emit(item)
  }

  showVideos(item: Card, uploaded: string): void {
    this.onSelect.emit(item)

  }

  showPalletesBlock(item: Card, uploaded: string): void {
    this.onSelect.emit(item)

  }

  showBrushesPhotoshopBlock(item: Card, uploaded: string): void {
    this.onSelect.emit(item)


  }
}
