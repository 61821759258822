import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {HeaderComponent} from '../../components/pages/header/header.component';
import {ScrollTopModule} from 'primeng/scrolltop';
import {ScrollNextPreviewComponent} from '../../components/pages/scroll-next-preview/scroll-next-preview.component';
import {ScrollCardsHorizontalComponent} from '../../components/pages/scroll-cards-horizontal/scroll-cards-horizontal.component';
import {BoxViewerPhotosComponent} from '../../components/box/box-viewer-photos/box-viewer-photos.component';
import {Card} from '../../interfaces/Card';
import {boxFileName} from '../../traits/string-tools';
import {PreloadComponent} from '../../components/pages/preload/preload.component';
import {OnlineStatusComponent} from '../../components/pages/online-status/online-status.component';
import {PreloadService} from '../../services/common/preload.service';
import {
  NgFor,
  NgIf
} from "@angular/common";
import {ListFeedComponent} from "../../components/list-feed/list-feed.component";
import {SidebarComponent} from "../../components/common/sidebar/sidebar.component";

@Component({
  selector: 'app-common-home',
  standalone: true,
  templateUrl: './common-home.component.html',
  imports: [
    NgIf,
    NgFor,
    HeaderComponent,
    ScrollTopModule,
    ScrollNextPreviewComponent,
    ScrollCardsHorizontalComponent,
    BoxViewerPhotosComponent,
    PreloadComponent,
    OnlineStatusComponent,
    ListFeedComponent,
    SidebarComponent,
  ]
})
export class CommonHomeComponent {
  @Input() site: any;
  @Input() dataPage: any;
  @Input() selectCard: Card = null;
   public showFrame: boolean = false;
  @Output() onSelectCourse = new EventEmitter();
  @Output() onClickRoute = new EventEmitter();

  constructor(public preloadService:PreloadService) {
    this.preloadService.showPreload();
  }

  ngOnInit() {
  // this.selectCard =  this.dataPage.site?.organizer.messenges[0]
  }
ngAfterViewInit(){
  console.log(this.dataPage.site.organizer.messenges)

}
  getId(selectCard: Card):string {
    return boxFileName(selectCard);
  }

  public showPhotos:boolean =false;
  public imageForGeneratePalette: string;
  selectItemCard(card: any) {
    this.selectCard = card;
    this.onSelectCourse.emit(card)
    if(this.selectCard)
    {
      this.showPhotos = true;
    }


  }
   boxFileName(model: any) {
    let boxId = model.id + '_' + model.createAt;
    return boxId.replace(/\s/g, "");
  }
  closePhotos($event: any) {
    console.log('closePhotos')
    this.selectCard = null ;
    this.showPhotos= false;
  }

  setGeneratePallete(photoItem: any) {
    console.log( photoItem,'photoItem' )
    // this.imageForGeneratePalette = photoItem.srcPreview
  }


}
/*
ngOnInit() {
  this.initMenu();

  this.initPreload();
  this.initgp()
  this.initSiteStatic();
  // this.initSiteLibCourses();

}


public initSiteStatic() {
  this.cd.showEditAll  = false;

  this.site = this.dataArtParty.site();
  let messengesList = this.site.organizer.messenges;//sortNumberDesc(site.organizer.messenges, 'rating');
  this.gp.selectStep = {duration: 0};
  this.gp.selectCourse = messengesList[0];
  this.gp.selectLesson = messengesList[0].array[0];
  this.gp.messengesClone = cloneModel(messengesList,false);
  this.gp.showCourses = true;
  this.gp.coursesCount = messengesList.length;
  this.gp.showSpinner = false;
  // this.gp.statistic.countMessenges = messengesList.length;


  /!*  this.cd.setStorageData('coursesLastView', {
      courseId: this.gp.selectCourse.id,
      lessonId: this.gp.selectLesson.id,
      stepTimeId: this.gp.selectLesson.steps[0],
      second: 0,
      speed: 1,
    });*!/
  // this.cd.initLastCourseInfo(this.gp);
  setTimeout(() => {
    this.cd.showCardsLength = 1000;
    this.cd.showCountCards = 1000;
  }, 1000);


}
public initSiteLibCourses() {
  this.cd.showEditAll  = true;
  // this.gp.showSpinner = true;
  this.server.initLibs('art-party', (site: any) => {
    this.site = site;
    let messengesList = site.organizer.messenges;//sortNumberDesc(site.organizer.messenges, 'rating');
    this.gp.selectStep = {duration: 0};
    this.gp.selectCourse = messengesList[0];
    this.gp.selectLesson = messengesList[0].array[0];
    this.gp.messengesClone = cloneModel(messengesList,false);
    this.gp.showCourses = true;
    this.gp.coursesCount = messengesList.length;
    this.gp.showSpinner = false;
    // this.gp.statistic.countMessenges = messengesList.length;


    /!*  this.cd.setStorageData('coursesLastView', {
        courseId: this.gp.selectCourse.id,
        lessonId: this.gp.selectLesson.id,
        stepTimeId: this.gp.selectLesson.steps[0],
        second: 0,
        speed: 1,
      });*!/
    // this.cd.initLastCourseInfo(this.gp);
    setTimeout(() => {
      this.cd.showCardsLength = 1000;
      this.cd.showCountCards = 1000;
    }, 1000);


  });


  /!*let timeout = setTimeout(() => {
    this.gp.showSpinner = false;
    this.gp.coursesMax = 100;
    clearTimeout(timeout);
  }, 2000);*!/


}
public initgp() {
  this.gp = this.dataArtParty.gp();
}
public saveUrl(url: any) {
  let urlSafe: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  return urlSafe;
}
/!* public initSiteStatic() {
   this.site = this.route.snapshot.data['site'];
   this.messenge = this.site.organizer.messenges[0];

   let arrays = this.messenge.array;
   this.cd.selectModel = arrays[0];
   setTimeout(() => {
     this.cd.showCardsLength = 20;
     this.cd.showCountCards = 100;
   }, 1000);
 }*!/



private initMenu() {
  this.cd.menuTopHamburgerItems = this.dataArtParty.menuTop();
  this.cd.menuBottomItems = this.dataArtParty.menuBottom();
}

private initPreload() {
  this.preloadSite = this.route.snapshot.data['preload'];
  this.setSeo(this.preloadSite);
}


private setSeo(site: any) {
  this.metaAng(site.title, site.tags, site.desc);
  this.cd.seoSite.h1 = site.h1;
  this.cd.seoSite.h2 = site.h2;
  this.cd.seoSite.h3 = site.h3;
  this.cd.seoSite.text = site.text;
}
private metaAng(title: any, keywords: any, description: any, themeColor: any = '#151515', author: any = 'artasalex') {
  let keywordsMap = keywords ? keywords.map((el: any) => {
    return ' ' + el.name;
  }) : '';

  this.title.setTitle(title);
  this.metaSeo.updateTag({name: 'keywords', content: keywordsMap});
  this.metaSeo.updateTag({name: 'description', content: description});
  this.metaSeo.updateTag({name: 'author', content: author});
  this.metaSeo.updateTag({name: 'theme-color', content: themeColor});
}

}*/
