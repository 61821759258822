<div id="list-feed"
     [class]="bgColor"
     [style.background-image]="'url(' + bgImage + ')'"
class="bg-[length:50px_50px] bg-opacity-10  bg-repeat min-h-screen flex justify-center items-center">
  <div class="mt-8 md:px-1 md:grid md:grid-cols-2 lg:grid-cols-3 gap-2 space-y-4 md:space-y-0">
<!--  .slice(0,maxView)-->
    <ng-container
      *ngFor="let  card  of  cards; let last = last;">
      <ng-container *ngIf="card.sw == true || last === true ">
          <app-card-feed
            [card]="card"
            (onSelect)="onSelect.emit($event)"
            (onSelectCourse)="onSelectCourse.emit($event)"
            (onShowPhotos)="onShowPhotos.emit($event)"
            (onClickRoute)="onClickRoute.emit($event)"
            (onFrameShow)="onFrameShow.emit($event)"
          ></app-card-feed>
      </ng-container>
    </ng-container>
  </div>
</div>


<!--
     <section [id]="feedItem.id">
          <app-card-course
            [feedItem]="feedItem"
            [gp]="gp"
            [site]="site"
          ></app-card-course>
        </section>
<div *ngIf="cd.showScrollPreview"
     class=" animate-fade-left animate-once animate-duration-[2000ms] sticky top-[30px] drop-shadow shadow-gray-600 z-20">
  <app-tool-cards-view
    [saveModel]="gp"
    [height]="80"
    [width]="80"
    [view]="'cost'"
    [arrayName]="'messengesClone'"
    [site]="site"
  ></app-tool-cards-view>
</div>
<app-filters-sort-view-btn  *ngIf="cd.showFilterSortBlock "
                            [gp]="gp"
></app-filters-sort-view-btn>

<app-filters-sort-block
  *ngIf="cd.showFilterSortBlock "
  [models]="cd.cloneModel(site.organizer.messenges,false)"
  [showSort]="true"
  [showFilter]="true"
  [showFilterChecked]="false"
  (onSort)="gp.messengesClone = $event"
  (onFilter)="gp.messengesClone = $event"
>
</app-filters-sort-block>

<app-scroll-id-navigator
[models]="feedList"
></app-scroll-id-navigator>
-->
