<section [id]="card.id"
         [class]="bgClass"
         class=" animate-fade-right animate-once m-4 max-w-sm  px-6 pt-6 pb-2 rounded-xl shadow-lg transform hover:scale-90 hover:scale-100 transition duration-500"
>
  <h3 [style.color]="card.color"
      style="font-family: peace_sans,serif;"
      class="mb-3 text-md tracking-wide text-amber-600">
      <span *ngIf="card.name">
         {{ card.name }}
      </span>

    <span *ngIf="card.srcIcon"
          [class]="bgClass +' '+ borderClass"
          class="  absolute -top-3 right-0 rounded-full  border-4 ">
         <img
           class="rounded-lg w-[50px] h-[50px]"
           [src]="card.srcIcon"
           [alt]="card.name"
         >
       </span>

  </h3>

  <div class="relative">

    <img *ngIf="card.srcPreview && card.name"
         class="w-full rounded-xl"
         [src]="card.srcPreview"
         [alt]="card.name"
         [style.border]="'2px solid ' + card.color +' '"
    />

    <p *ngIf="card.status"
       [class]="'bg-amber-300'"
       class=" absolute top-0 text-zinc-800 font-semibold py-1 px-3 rounded-br-lg rounded-tl-lg">
        <span *ngIf="card.status">
          {{ card.status }}
        </span>
    </p>

    <p *ngIf="!card.costDiscount"
       [class]="bgClass"

       class=" absolute top-0 right-0 bg-amber-300 text-zinc-800 font-semibold py-1 px-3 rounded-tr-lg rounded-bl-lg"
    >
        <span *ngIf="!card.favorite "
              (click)="onAddFavorite.emit(card)">
          <span>Add</span>
          <i [class]="theme.icon.favoriteOpen"></i>
        </span>

      <span *ngIf="card.favorite"
            (click)="onDeleteFromFavorite.emit(card)">
         <span>Remove </span>
          <i [class]="theme.icon.favoriteClose"></i>
        </span>
    </p>
    <p *ngIf="card.costDiscount"
       [class]="'bg-amber-300'"
       class=" absolute top-0 right-0 bg-amber-300 text-zinc-800 font-semibold py-1 px-3 rounded-tr-lg rounded-bl-lg"
    >
        <span>
          <span>{{ card.costDiscount }}</span>
        </span>
    </p>
<!--    *ngIf=" card.photosEnable && !card.enableGoGallery"-->
    <!--<app-btn-premium-product

      (onClick)="showPhotosBoxBtn(card ,'photos');"
      [srcPreview]="theme.iconSrc.photos"
      [label]="'Photos'"
      [absoluteClass]="'absolute left-[0] bottom-[5px] '"
      [animateClass]="'animate-pulse animate-infinite animate-ease-in-out animate-duration-[6000ms]'"
      [colorClass]="bgClass+' text-amber-600'"
    >
    </app-btn-premium-product>-->

    <!--<app-btn-premium-product
      *ngIf="card.brushesEnable"
      [label]="'Brushes'"
      [srcPreview]="theme.iconSrc.brushes"
      [srcIcon]="theme.iconSrc.premium"
      [showSrcIcon]="false"
      [absoluteClass]="'absolute -left-[3px] bottom-[5px]  '"
      [animateClass]="'animate-pulse animate-infinite animate-ease-in-out animate-duration-[6000ms]'"
      [colorClass]="bgClass+' text-amber-600'"
      (onClick)="showBrushesPhotoshopBlock(card ,'photos')"
    >
    </app-btn-premium-product>-->

    <!--<app-btn-premium-product
      *ngIf="card.palletesEnable"
      [label]="'Palletes'"
      [srcPreview]="theme.iconSrc.palletes"
      [srcIcon]="theme.iconSrc.premium"
      [showSrcIcon]="true"
      [absoluteClass]="'absolute left-[70px] bottom-[5px]   '"
      [animateClass]="'animate-pulse animate-infinite animate-ease-in-out animate-duration-[6000ms]'"
      [colorClass]="bgClass+' text-amber-600'"
      (onClick)="showPalletesBlock(card ,'photos')"
    >
    </app-btn-premium-product>-->

    <!--<app-btn-premium-product
      *ngIf="card.referencesEnable"
      [label]="'Refs'"
      [srcPreview]="theme.iconSrc.references"
      [srcIcon]="theme.iconSrc.premium"
      [showSrcIcon]="true"
      [absoluteClass]="'absolute left-[70px] bottom-[5px]   '"
      [animateClass]="'animate-pulse animate-infinite animate-ease-in-out animate-duration-[6000ms]'"
      [colorClass]="bgClass+' text-amber-600'"
      (onClick)="showPhotosBoxBtn(card ,'uploaded');"
    >
    </app-btn-premium-product>-->

    <!--<app-btn-premium-product
      *ngIf="(card.videos && card.videosEnable) "
      [label]="'Video'"
      [srcPreview]="theme.iconSrc.videos"
      [srcIcon]="theme.iconSrc.video"
      [showSrcIcon]="true"
      [absoluteClass]="'absolute left-[70px] bottom-[5px]   '"
      [animateClass]="'animate-pulse animate-infinite animate-ease-in-out animate-duration-[6000ms]'"
      [colorClass]="bgClass+' text-amber-600'"
      (onClick)="showVideos(card,'videos');"
    >
    </app-btn-premium-product>-->


<!--    <app-gif-generator *ngIf="card.gifsEnable "
                       [photos]="card.favorites">
    </app-gif-generator>-->

  </div>
  <!--<app-text-slice *ngIf="card.desc"
    [text]="card.desc"
  ></app-text-slice>-->

  <ng-container>
    <div class="my-4">
      <div *ngIf="card.cat"
           class="flex space-x-1 items-center"
      >
        <app-svg-icon></app-svg-icon>

        <p class="text-zinc-400">{{ card.cat }}</p>
      </div>
      <div *ngIf="card.duration"
           class="flex space-x-1 items-center"
      >
          <span class=" h-6 w-6 text-amber-600 mb-1.5 ">
            <i [class]="theme.icon.clock "></i>
          </span>
        <p class="text-zinc-400">{{ card.duration }}</p>
      </div>

      <div *ngIf="card.painter"
           class="flex space-x-1 items-center"
      >
          <span class=" h-6 w-6 text-amber-600 mb-1.5 ">
           <i [class]="theme.icon.brush"></i>
          </span>
        <p class="text-zinc-400">+ Drawing trainer(painter)</p>
      </div>

      <div *ngIf="card.array.length>2"
           class="flex space-x-1 items-center">

          <span class=" h-6 w-6 text-amber-600 mb-1.5 ">
            <i [class]="theme.icon.list"></i>
          </span>

        <p class="text-zinc-400">
          <span *ngIf="card.statistic">
              {{ card.statistic.countArrays }}
            </span>

          <span *ngIf="card.continueEnable">
             <!--<img
               class="rounded-lg w-[15px] h-[15px]"
               [src]="'/assets/icons/colors-tools/2779847.png'"
                  [alt]="'continue'">-->
          </span>
        </p>
      </div>

      <div *ngIf="card.level" class="flex space-x-1 items-center">
          <span class=" h-6 w-6 text-amber-600 mb-1.5 ">
           <i [class]="theme.icon.level"></i>
          </span>
        <p class="text-zinc-400">
          <span>{{ card.level }}</span>
        </p>
      </div>


      <div *ngIf="card.additionally" class="flex space-x-1 items-center">
          <span class=" h-6 w-6 text-amber-600 mb-1.5 ">
              <i [class]="theme.icon.folder"></i>
          </span>
        <p class="text-zinc-400"> {{ card.additionally }}</p>
      </div>


     <!-- <ng-container *ngIf="card.list">
        <ng-container *ngFor="let  characteristicItem  of card.list  ; let last = last;">

          <div class="flex space-x-1 items-center">
          <span *ngIf="characteristicItem.icon"
                class=" h-6 w-6 text-amber-600 mb-1.5 ">
            <i [class]="characteristicItem.icon"></i>
          </span>
            <p *ngIf="characteristicItem.name"
               class="text-zinc-400">
              {{ characteristicItem.name }}
            </p>
          </div>
        </ng-container>
      </ng-container>-->

<!--*ngIf="card.moreEnable"-->
      <app-btn-go
                  [label]="'Refs'"
                  [model]="card"
                  [srcPreview]="theme.iconSrc.references"
                  [srcIcon]="theme.iconSrc.premium"
                  [showSrcIcon]="true"
                  [absoluteClass]="'absolute left-[70px] bottom-[5px]   '"
                  [animateClass]="'animate-pulse animate-infinite animate-ease-in-out animate-duration-[6000ms]'"
                  [colorClass]="bgClass+' text-amber-600'"
                  (onClick)="showPhotosBoxBtn(card ,'photos');"
                  (onShowCourse)="onSelectCourse.emit(card)"
                  (onFrameShow)="onFrameShow.emit(card)"
                  (onShowPhotosModal)="showPhotosBoxBtn(card ,'photos');"
                  (onClickRoute)="onClickRoute.emit(card)"
      ></app-btn-go>


      <!--<div *ngIf="card.tags " class="mt-2 overflow-x-auto">
        <div class="flex space-x-1 items-center">
          <ng-container *ngFor="let  tagItem  of  card.tags.slice(0,8);">
            <div [class]="bgGlobalClass"
                 class="ml-4   text-[10px] inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full"
            >
              <span class="text-amber-300 "><i [class]="theme.icon.tag "></i></span>
              <span class="mr-1 text-zinc-300">{{ tagItem.name }}</span>
            </div>
          </ng-container>
        </div>
      </div>-->
    </div>
  </ng-container>

 <!-- <ng-container *ngIf="card.price">
    <ng-container *ngFor="let  priceItem  of card.price  ; let last = last;">

      <div class="flex space-x-1 items-center">
          <span *ngIf="(priceItem.icon && !priceItem.srcIcon) "
                class=" h-6 w-6 text-amber-600 mb-1.5 ">
            <i [class]="priceItem.icon"></i>
          </span>

        <span *ngIf="(priceItem.srcIcon && !priceItem.icon) "
              class=" h-6 w-6 text-amber-600 mb-1.5 ">
               <img
                 [src]="priceItem.srcIcon"
                 alt="premium-icon"
                 class="  [20px] w-[20px]"
               >
          </span>

        <p *ngIf="priceItem.name"
           class="text-zinc-400">
          {{ priceItem.name }}
        </p>
        <p *ngIf="priceItem.desc"
           class="text-zinc-400">
          {{ priceItem.name }}
        </p>
        <p *ngIf="priceItem.cost"
           class="text-zinc-400">
          {{ priceItem.cost }}
        </p>
      </div>
    </ng-container>
  </ng-container>-->

</section>
