import {
  Component,
  OnInit
} from '@angular/core';
import {
  JsonPipe,
  NgForOf,
  NgIf
} from "@angular/common";
import { HeaderComponent } from 'src/app/components/pages/header/header.component';
import {NavbarTopComponent} from "../../../components/pages/navbar-top/navbar-top.component";
import {CommonHomeComponent} from "../../common-home/common-home.component";
import {PageArtasServices} from "../../../services/pages/page-artas.services";
import {
  ActivatedRoute,
  Router
} from "@angular/router";
import {
  map,
  Observable,
  switchMap
} from "rxjs";
import { BoxServices } from 'src/app/services/box/box.services';
import {ViewGridComponent} from "../../../components/view-grid/view-grid.component";
import {ContactsLeftComponent} from "../../../components/contacts-left/contacts-left.component";
import {ProductListRightComponent} from "../../../components/product-list-right/product-list-right.component";
import {ProductMoreComponent} from "../../../components/product-more/product-more.component";
import {NavbarTopArtasComponent} from "../../../components/navbar-top-artas/navbar-top-artas.component";
@Component({
  selector: 'app-page-artas-products',
  templateUrl: './page-artas-products.component.html',
  standalone:true,
  imports: [
    HeaderComponent,
    NavbarTopComponent,
    CommonHomeComponent,
    NgForOf,
    NgIf,
    NavbarTopArtasComponent,
    JsonPipe,
    ViewGridComponent,
    ContactsLeftComponent,
    ProductListRightComponent,
    ProductMoreComponent,
    NavbarTopArtasComponent,
  ],
})
export class PageArtasProductsComponent implements OnInit{
 public card:any
  public photos: any[];
  public cards: any[];

  constructor(public dataPage: PageArtasServices,
              private router: Router,
              private route: ActivatedRoute,
              public boxServices: BoxServices,
              ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
  this.card =   this.dataPage.findCard(params.slug)
      if(this.card){
        this.initBox(this.card)
       this.cards = this.dataPage.getCards();
      }
    });

}

  public initBox(card){
    // boxId = boxId.replace(/\s/g, "")

    let boxId:any = this.boxFileName(card)
    this.boxServices.findCardBoxById(boxId,
      (responseBox: any): void => {
        // this.box = responseBox;
        this.photos =responseBox.photos
        console.log(responseBox,'responseBox')
      });
  }
  boxFileName(model: any) {
    let boxId = model.id + '_' + model.createAt;
    return boxId.replace(/\s/g, "");
  }

  setSelect(card: any) {
    this.router.navigate(['zakazat-sharj-po-foto/',card.id]);
    this.card = card;
}
}
