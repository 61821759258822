@if (btnGoServices.showRouterLink(model)) {
  <div *ngIf="model.info" class="font-bold text-xl mb-2">
    <p class="text-amber-500 text-base">{{ model.info }}</p>
  </div>
  <button  [routerLink]="model.routerLink" (click)=" onClickRoute.emit(model);"
      class="focus:ring-4  transform active:scale-75 transition-transform mt-4 text-xl w-full  bg-sky-300 py-2 rounded-xl shadow-lg"
  >
    <span class="ml-5 text-xl text-zinc ">{{ goBtnText }}</span>
   <!-- <app-share-url
    ></app-share-url>-->
  </button>
}


<!--

@if (btnGoServices.showPremium(model, showPremium)) {

  @if (btnGoServices.showOrderForm(model)) {

    <div *ngIf="model.info" class="font-bold text-xl mb-2">
      <p class="text-amber-500 text-base">{{ model.info }}</p>
    </div>
    <button (click)="showCardOrder = true; selectModel = model; "
            class=" focus:ring-4  transform active:scale-75 transition-transform mt-4 text-xl w-full  bg-sky-300 py-2 rounded-xl shadow-lg"
    >
      <span class="ml-5 text-xl text-zinc ">{{ orderText }}</span>
    </button>
  }


  @if (btnGoServices.showGoGallery(model)) {
    <button
      (click)="showPhotosBoxBtn(model,'photos');"
      class=" block focus:ring-4 transform active:scale-75 transition-transform mt-4 text-xl w-full  bg-sky-300 py-2 rounded-xl shadow-lg"
    >
      <span class="ml-5 text-xl text-zinc ">Галерея</span>
    </button>
  }


  @if (btnGoServices.showUrl(model)) {
    <div *ngIf="model.info" class="font-bold text-xl mb-2">
      <p class="text-amber-500 text-base">{{ model.info }}</p>
    </div>
    <a [href]="model.srcUrl" target="_blank"
       class=" block focus:ring-4  transform active:scale-75 transition-transform mt-4 text-xl w-full  bg-sky-300 py-2 rounded-xl shadow-lg"
    >
      <span class="ml-5 text-xl text-zinc ">{{ urlText }}</span>
    </a>
  }
}
-->
