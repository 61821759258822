import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {ScrollPanelModule} from "primeng/scrollpanel";
import {
  NgForOf,
  NgIf
} from "@angular/common";
import {OrderFormComponent} from "../order-form/order-form.component";
import {MyDataServices} from "../../services/my-data.services";
import {windowHeight} from "../../traits/window-tools";
import {
  brushIcon,
  clockIcon,
  folderIcon,
  levelIcon,
  listIcon
} from "../../traits/icons-tools";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {SvgIconComponent} from "../image/svg-icon/svg-icon.component";
@Component({
  selector: 'app-product-more',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    ScrollPanelModule,
    OrderFormComponent,
    OverlayPanelModule,
    SvgIconComponent,
  ],
  templateUrl: './product-more.component.html',
})
export class ProductMoreComponent {
  @Input() public  cards:any;
  @Input() public  selectCard:any;
  @Output() onSelectCard = new EventEmitter();

  constructor(public myData:MyDataServices,) {
  }
  ngOnInit() {
  }
  ngAfterViewInit(){
  }


  protected readonly windowHeight = windowHeight;
  protected readonly levelIcon = levelIcon;
  protected readonly brushIcon = brushIcon;
  protected readonly listIcon = listIcon;
  protected readonly clockIcon = clockIcon;
  protected readonly folderIcon = folderIcon;
}
